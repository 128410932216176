import { CustomObject } from '@negotium/models';
import commonRequestHeaders from '../common/requestHeaders';
import { AxiosError } from 'axios';

export const signIn = async (
	body: CustomObject
): Promise<{
	status: number;
	data: string;
	message: string;
}> => {
	'use client';
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(`${process.env['NEXT_PUBLIC_API_URL']}/login`, {
			method: 'post',
			credentials: 'include',
			headers: headers,
			body: JSON.stringify(body),
		});

		if (!promise.ok) {
			if (promise.status === 401) {
				return { status: 401, data: 'unauthorized', message: 'unauthorized' };
			}
		}

		const { result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}
		return {
			status: 200,
			data: 'loginSuccess',
			message: 'loginSuccess',
		};
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};

export const signOut = async (): Promise<boolean> => {
	'use client';
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL']}/logout`,
			{
				method: 'get',
				credentials: 'include',
				headers: headers,
			}
		);
		await promise.json();

		return true;
	} catch (error) {
		return false;
	}
};
