'use client';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

type ImageWithFallbackProps = {
	fallbackImage: string;
	alt: string;
	src: string;
	width?: number;
	height?: number;
	adjust?: boolean;
	style?: object;
};

export function ImageWithFallback({
	fallbackImage,
	alt,
	src,
	width = 150,
	height = 50,
	adjust = false,
	style,
}: ImageWithFallbackProps) {
	const [error, setError] = useState<EventTarget | null>(null);

	useEffect(() => {
		setError(null);
	}, [src]);

	return (
		<Image
			alt={alt}
			onError={(e) => setError(e.target)}
			src={
				error
					? fallbackImage
					: `${process.env.NEXT_PUBLIC_MEDIA_URL_DISPLAY}${src}`
			}
			width={adjust ? 0 : width}
			height={adjust ? 0 : height}
			style={style}
			{...(adjust && {
				sizes: '100vw',
				fill: true,
				style: { objectFit: 'cover' },
			})}
			placeholder='blur'
			blurDataURL={`${
				error
					? fallbackImage
					: `${process.env.NEXT_PUBLIC_MEDIA_URL_DISPLAY}${src}`
			}?w=400&q=50`}
		/>
	);
}
