'use client';
import { decryptAES, encryptAES } from '@negotium/common';
import { createContext, useContext, useEffect, useState } from 'react';

interface ContextCartShopType {
	cartShopId: string;
	countProductCartShop: number;
	saveCartShopIdLocalStorage: (cartId: string) => void;
	setCartShopId: (cartId: string) => void;
	setCountProductCartShop: (count: number) => void;
	getCartShopIdLocalStorage: () => void;
	changeCountProducts: () => void;
	discountProducts: () => void;
	clearCartShopLocalStorage: () => void;
}
const ContextCartShop = createContext<ContextCartShopType>({
	cartShopId: '',
	countProductCartShop: 0,
	saveCartShopIdLocalStorage: () => {},
	setCartShopId: () => {},
	setCountProductCartShop: () => {},
	getCartShopIdLocalStorage: () => {},
	changeCountProducts: () => {},
	clearCartShopLocalStorage: () => {},
	discountProducts: () => {},
});

export const CartShopProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [cartShopId, setCartShopId] = useState<string>('');
	const [countProductCartShop, setCountProductCartShop] = useState<number>(0);

	function changeCountProducts() {
		setCountProductCartShop(countProductCartShop + 1);
	}
	function discountProducts() {
		setCountProductCartShop(countProductCartShop - 1);
	}

	function saveCartShopIdLocalStorage(cartShopId: string) {
		const encryptedCartShop = encryptAES(
			cartShopId,
			process.env.NEXT_PUBLIC_ENCRYPT_KEY || ''
		);
		localStorage.setItem('cartShopUser', encryptedCartShop);
		setCartShopId(cartShopId);
	}
	function getCartShopIdLocalStorage() {
		const cartShopUserLocal = localStorage.getItem('cartShopUser');

		if (cartShopUserLocal) {
			const decryptedCartShop = decryptAES(
				cartShopUserLocal,
				process.env.NEXT_PUBLIC_ENCRYPT_KEY || ''
			);
			if (decryptedCartShop) {
				setCartShopId(decryptedCartShop as string);
			}
		}
	}

	function clearCartShopLocalStorage() {
		localStorage.removeItem('cartShopUser');
	}

	useEffect(() => {
		getCartShopIdLocalStorage();
	}, []);

	return (
		<ContextCartShop.Provider
			value={{
				cartShopId,
				countProductCartShop,
				setCartShopId,
				saveCartShopIdLocalStorage,
				setCountProductCartShop,
				changeCountProducts,
				getCartShopIdLocalStorage,
				clearCartShopLocalStorage,
				discountProducts,
			}}
		>
			{children}
		</ContextCartShop.Provider>
	);
};

export const useContextCartShop = () => useContext(ContextCartShop);

export default ContextCartShop;
