'use client';
import { createContext, useContext, useState } from 'react';

interface ContextGeneralType {
	showLogin: boolean;
	setShowLogin: (value: boolean) => void;
}
const ContextGeneral = createContext<ContextGeneralType>({
	showLogin: false,
	setShowLogin: () => {},
});

export const GeneralProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [showLogin, setShowLogin] = useState<boolean>(false);

	return (
		<ContextGeneral.Provider
			value={{
				showLogin,
				setShowLogin,
			}}
		>
			{children}
		</ContextGeneral.Provider>
	);
};

export const useContextGeneral = () => useContext(ContextGeneral);

export default ContextGeneral;
