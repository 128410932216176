import {
	IRecipients,
	IRecipientsList,
	IRecipientsOption,
} from '@negotium/models';
import commonRequestHeaders from '../common/requestHeaders';

export const getRecipients = async (
	limit: number,
	offSet: number
): Promise<IRecipientsList> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/recipients/${offSet}/${limit}`,
			{
				method: 'get',
				credentials: 'include',
				headers: headers,
			}
		);
		const { docs } = await promise.json();

		return {
			status: 200,
			docs: [...docs],
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
};
export const createRecipient = async (
	body: Partial<IRecipients>
): Promise<IRecipientsList> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/recipients/create`,
			{
				method: 'post',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify(body),
			}
		);
		const { docs } = await promise.json();

		return {
			status: 200,
			docs: [...docs],
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
};
export const updateRecipient = async (
	body: Partial<IRecipients>
): Promise<IRecipientsList> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/recipients/edit/${body.recipientId}`,
			{
				method: 'put',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify(body),
			}
		);
		await promise.json();

		return {
			status: 200,
			docs: [],
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
};
export const getRecipientById = async (
	id: string
): Promise<IRecipientsOption> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/recipients/${id}`,
			{
				method: 'get',
				credentials: 'include',
				headers: headers,
			}
		);
		const { data } = await promise.json();

		return {
			status: 200,
			data,
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
};
