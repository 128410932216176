import {
	CustomObject,
	IResultAddRate,
	IResultAddUserToWhishList,
	IResultDictionaryToFilter,
	IResultProductsCatalog,
	IResultProductsDetails,
} from '@negotium/models';
import commonRequestHeaders from '../common/requestHeaders';
import { AxiosError } from 'axios';

export const getProductsCatalog = async (
	body: CustomObject | BodyInit | null
): Promise<IResultProductsCatalog> => {
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/storages/public/filterProductByStoragesToStore`,
			{
				method: 'post',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify(body),
			}
		);

		const result = await promise.json();
		if (result?.docs) {
			return {
				status: 200,
				docs: result.docs,
				total: result.metadata ? result.metadata?.total : 0,
			};
		} else {
			return { status: 200, docs: [], total: 0 };
		}
	} catch (error) {
		return { status: 500, docs: [], total: 0 };
	}
};
export const addUserToWhishList = async (
	storageUniqueId: string
): Promise<IResultAddUserToWhishList> => {
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/storages/addUserToWhishList/${storageUniqueId}`,
			{
				method: 'put',
				credentials: 'include',
				headers: headers,
			}
		);

		const result = await promise.json();

		return { status: 200, data: result.data };
	} catch (error) {
		throw new Error((error as Error).message);
	}
};
export const getPopularProducts = async (): Promise<IResultProductsCatalog> => {
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/cartShop/public/popular/products`,
			{
				method: 'get',
				credentials: 'include',
				headers: headers,
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				return { status: 401, docs: [], total: 0 };
			}
		}
		const { data, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			docs: data,
			total: 12,
		};
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};
export const getRecentProducts = async (): Promise<IResultProductsCatalog> => {
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/storages/public/products/recent`,
			{
				method: 'get',
				credentials: 'include',
				headers: headers,
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				return { status: 401, docs: [], total: 0 };
			}
		}
		const { docs, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			docs: docs,

			total: docs.length,
		};
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};
export const getDiscountedProducts =
	async (): Promise<IResultProductsCatalog> => {
		try {
			const headers = await commonRequestHeaders();
			const promise = await fetch(
				`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/storages/public/products/discounted`,
				{
					method: 'get',
					credentials: 'include',
					headers: headers,
				}
			);

			if (!promise.ok) {
				if (promise.status === 401) {
					return { status: 401, docs: [], total: 0 };
				}
			}
			const { docs, result, message } = await promise.json();
			if (result === 2) {
				throw new Error(message);
			}

			return {
				status: 200,
				docs: docs,
				total: docs.length,
			};
		} catch (error) {
			throw new Error((error as AxiosError).message);
		}
	};
export const getRelationsProductsByCategories = async (
	categories: Array<string>,
	productId: string
): Promise<IResultProductsCatalog> => {
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/storages/public/products/relationsCategories`,
			{
				method: 'post',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify({ categories, productId }),
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				return { status: 401, docs: [], total: 0 };
			}
		}
		const { docs, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			docs: docs,
			total: docs.length,
		};
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};
export const getProductDetails = async (
	productId: string
): Promise<IResultProductsDetails> => {
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/storages/public/products/details/${productId}`,
			{
				method: 'get',
				credentials: 'include',
				headers: headers,
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				return { status: 401, data: null, message: promise.statusText };
			}
		}
		const { docs, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			data: docs,
			message: message,
		};
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};

export const addUserRateToProduct = async (
	storageUniqueId: string,
	rate: number
): Promise<IResultAddRate> => {
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/storages/addRateToProduct/${storageUniqueId}`,
			{
				method: 'put',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify({ rate: rate }),
			}
		);

		const { result, message, data } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return { status: 200, data: data };
	} catch (error) {
		throw new Error((error as Error).message);
	}
};

export const getMinusAndMajorPriceProduct =
	async (): Promise<IResultDictionaryToFilter> => {
		try {
			const headers = await commonRequestHeaders();
			const promise = await fetch(
				`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/storages/public/getMinusAndMajorPriceProduct`,
				{
					method: 'post',
					credentials: 'include',
					headers: headers,
					body: JSON.stringify({}),
				}
			);

			const result = await promise.json();
			if (result?.data) {
				return {
					status: 200,
					data: result.data,
					message: 'Get dictionary to filter products in catalog',
				};
			} else {
				return { status: 200, data: null, message: '' };
			}
		} catch (error) {
			return { status: 500, data: null, message: '' };
		}
	};
