import { CustomObject, IResultFilterCategories } from '@negotium/models';
import commonRequestHeaders from '../common/requestHeaders';
import { AxiosError } from 'axios';

export const getCategoriesByParentCode = async (
	parenCode: number
): Promise<IResultFilterCategories> => {
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/categories/public/${parenCode}`,
			{
				method: 'get',
				credentials: 'include',
				headers: headers,
			}
		);
		const result = await promise.json();
		if (result?.docs) {
			return {
				status: 200,
				docs: result.docs,
				total: result.docs.length,
			};
		} else {
			return { status: 200, docs: [], total: 0 };
		}
	} catch (error) {
		return { status: 500, docs: [], total: 0 };
	}
};
export const groupListCategories = async (
	body: CustomObject | BodyInit | null
): Promise<IResultFilterCategories> => {
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/categories/groupCategories`,
			{
				method: 'post',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify(body),
			}
		);
		const result = await promise.json();

		if (result) {
			return {
				status: 200,
				docs: result,
				total: result.length || 0,
			};
		} else {
			return { status: 200, docs: [], total: 0 };
		}
	} catch (error) {
		return { status: 500, docs: [], total: 0 };
	}
};
export const getCategoriesParent =
	async (): Promise<IResultFilterCategories> => {
		try {
			const headers = await commonRequestHeaders();
			const promise = await fetch(
				`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/categories/public/getCategoriesParent`,
				{
					method: 'get',
					credentials: 'include',
					headers: headers,
				}
			);

			if (!promise.ok) {
				if (promise.status === 401) {
					return { status: 401, docs: [], total: 0 };
				}
			}
			const { data, result, message } = await promise.json();
			if (result === 2) {
				throw new Error(message);
			}

			return {
				status: 200,
				docs: [...data],
				total: 0,
			};
		} catch (error) {
			throw new Error((error as AxiosError).message);
		}
	};
