import {
	IResultCreateWholesalerRequest,
	IWholesalerRequestSave,
} from '@negotium/models';
import commonRequestHeaders from '../common/requestHeaders';

export const createWholesalerRequest = async (
	body: Partial<IWholesalerRequestSave>
): Promise<IResultCreateWholesalerRequest> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/wholesalerRequest/create`,
			{
				method: 'post',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify(body),
			}
		);
		const { data, message } = await promise.json();

		return {
			status: 200,
			data: data,
			message: message,
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
};
export const updateWholesalerRequest = async (
	wholesalerRequestId: string,
	body: Partial<IWholesalerRequestSave>
): Promise<IResultCreateWholesalerRequest> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/wholesalerRequest/edit/${wholesalerRequestId}`,
			{
				method: 'put',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify(body),
			}
		);
		const { data, message } = await promise.json();

		return {
			status: 200,
			data: data,
			message: message,
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
};
