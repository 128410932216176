import {
	CustomObject,
	IActiveDesactive,
	IProfileEdit,
	IResultCreateUser,
	IResultFilterUser,
	IResultUsersById,
	IUser,
} from '@negotium/models';
import commonRequestHeaders from '../common/requestHeaders';
import { AxiosError } from 'axios';

export const changePasswordClientUser = async (
	password: string,
	email: string,
	code: string
): Promise<IResultCreateUser> => {
	try {
		const headers = await commonRequestHeaders();
		const body = {
			password,
			email,
			code,
		};
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/user/changePasswordClient`,
			{
				method: 'post',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify(body),
			}
		);
		const result = await promise.json();
		if (result?.result === 2) {
			throw new Error(result.message);
		}

		return {
			status: 200,
			data: '',
			message: result.message,
		};
	} catch (error) {
		return { status: 500, data: '', message: (error as Error).message };
	}
};
export const forgotPassword = async (
	email: string,
	lng: string
): Promise<IResultCreateUser> => {
	try {
		const headers = await commonRequestHeaders();
		const body = {
			email,
			lng,
		};

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/user/forgotPasswordCustomer`,
			{
				method: 'post',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify(body),
			}
		);
		const result = await promise.json();
		if (result?.result === 2) {
			throw new Error(result.message);
		}

		return {
			status: 200,
			data: '',
			message: result.message,
		};
	} catch (error) {
		return { status: 500, data: '', message: (error as Error).message };
	}
};

export const activateAccount = async (
	email: string,
	code: string
): Promise<IResultCreateUser> => {
	try {
		const headers = await commonRequestHeaders();
		const body = {
			email,
			code,
		};
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/user/activateAccount`,
			{
				method: 'post',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify(body),
			}
		);
		const result = await promise.json();
		if (result?.result === 2) {
			throw new Error(result.message);
		}

		return {
			status: 200,
			data: '',
			message: result.message,
		};
	} catch (error) {
		return { status: 500, data: '', message: (error as Error).message };
	}
};

export const usersEditProfile = async (
	body: IProfileEdit
): Promise<IResultCreateUser> => {
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL']}/user/public/profile`,
			{
				method: 'put',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify(body),
			}
		);
		const result = await promise.json();
		if (result.result === 2) {
			throw new Error(result.message);
		}

		return {
			status: 200,
			data: result.data,
			message: result.message,
		};
	} catch (error) {
		return { status: 500, data: '', message: (error as Error).message };
	}
};
interface ResultProfile {
	status: number;
	data: IProfileEdit | null;
	message: string;
}
export const usersGetProfile = async (): Promise<ResultProfile> => {
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/user`,
			{
				method: 'get',
				credentials: 'include',
				headers: headers,
			}
		);
		const result = await promise.json();
		if (result.result === 2) {
			throw new Error(result.message);
		}

		return {
			status: 200,
			data: result.data,
			message: result.message,
		};
	} catch (error) {
		return { status: 500, data: null, message: (error as Error).message };
	}
};

export const changePasswordAdminUser = async (
	newPassword: string,
	oldPassword: string
): Promise<IResultCreateUser> => {
	try {
		const headers = await commonRequestHeaders();
		const result = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/user/changePassword`,
			{
				method: 'post',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify({ newPassword, oldPassword }),
			}
		);
		const { message, data } = await result.json();
		if (data === 'updatePasswordSuccess') {
			return {
				status: 200,
				data: '',
				message: data,
			};
		} else {
			throw new Error(message);
		}
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};

export const usersFilter = async (
	body: CustomObject | BodyInit | null
): Promise<IResultFilterUser> => {
	'use client';
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL']}/user/filter`,
			{
				method: 'post',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify(body),
			}
		);
		if (!promise.ok) {
			if (promise.status === 401) {
				return { status: 401, docs: [], total: 0 };
			}
		}
		const result = await promise.json();
		if (result?.docs) {
			return {
				status: 200,
				docs: [...result.docs],
				total: result.metadata ? result.metadata?.total : 0,
			};
		}
		return { status: promise.status, docs: [], total: 0 };
	} catch (error) {
		return { status: 500, docs: [], total: 0 };
	}
};
export const usersById = async (id: string): Promise<IResultUsersById> => {
	'use client';
	try {
		const headers = await commonRequestHeaders();
		const promesa = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL']}/user/${id}`,
			{
				method: 'get',
				credentials: 'include',
				headers: headers,
			}
		);
		const result = await promesa.json();
		if (result.data) {
			return {
				status: 200,
				data: result.data,
				message: result.message,
			};
		} else {
			return { status: 200, data: null, message: '' };
		}
	} catch (error) {
		return { status: 500, data: null, message: '' };
	}
};
export const usersCreate = async (body: IUser): Promise<IResultCreateUser> => {
	'use client';
	try {
		const headers = await commonRequestHeaders();

		const promesa = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL']}/user/create`,
			{
				method: 'post',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify(body),
			}
		);
		const result = await promesa.json();

		if (result.result === 2) {
			throw new Error(result.message);
		}

		return {
			status: result.data ? 200 : 500,
			data: result.data,
			message: result.message,
		};
	} catch (error) {
		return { status: 500, data: '', message: (error as Error).message };
	}
};
export const usersEdit = async (
	id: string,
	body: IUser
): Promise<IResultCreateUser> => {
	'use client';
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL']}/user/${id}`,
			{
				method: 'put',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify(body),
			}
		);
		const result = await promise.json();
		if (result.result === 2) {
			throw new Error(result.message);
		}

		return {
			status: 200,
			data: result.data,
			message: result.message,
		};
	} catch (error) {
		return { status: 500, data: '', message: (error as Error).message };
	}
};
export const usersDelete = async (id: string): Promise<IResultCreateUser> => {
	'use client';
	try {
		const headers = await commonRequestHeaders();
		const result = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL']}/user/${id}`,
			{
				method: 'delete',
				credentials: 'include',
				headers: headers,
			}
		);
		const { data } = await result.json();
		if (data?.docs) {
			return {
				status: 200,
				data: data.data,
				message: data.message,
			};
		} else {
			return { status: 200, data: '', message: '' };
		}
	} catch (error) {
		return { status: 500, data: '', message: '' };
	}
};

export const activeUser = async (
	userId: string,
	body: IActiveDesactive
): Promise<IResultCreateUser> => {
	'use client';
	try {
		const headers = await commonRequestHeaders();
		const result = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL']}/user/activeDesactive/${userId}`,
			{
				method: 'put',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify(body),
			}
		);
		const { data } = await result.json();
		if (data?.docs) {
			return {
				status: 200,
				data: data.data,
				message: data.message,
			};
		} else {
			return { status: 200, data: '', message: '' };
		}
	} catch (error) {
		return { status: 500, data: '', message: '' };
	}
};
