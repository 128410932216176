export function formatCurrency(number: number, currency = 'USD') {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency,
	}).format(number);
}

export const numWithComma = (number: number | string) =>
	Number(number)
		.toFixed(2)
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
