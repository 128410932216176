import {
	CustomObject,
	IImage,
	IResultEndpoint,
	IResultImages,
} from '@negotium/models';
import commonRequestHeadersFile from '../common/requestHeadersFile';
import commonRequestHeaders from '../common/requestHeaders';

export async function MediaService(
	env: string,
	param: Array<string> | [],
	body: CustomObject | null,
	docs: Array<File> | [],
	next?: (data: number) => Promise<IResultEndpoint> | null
) {
	const data1 = new FormData();

	if (docs && docs.length > 0) {
		for (let index = 0; index < docs.length; index += 1) {
			const element = docs[index];
			data1.append('file', element);
		}
	}
	if (body && typeof body === 'object') {
		Object.keys(body).forEach((key) => {
			if (Object.hasOwnProperty.call(body, key)) {
				if (body && key && body[key] && typeof body[key] !== 'undefined') {
					data1.append(key, body[key]?.toString() || '');
				}
			}
		});
	}
	const headers = await commonRequestHeadersFile();

	const promise = await fetch(
		`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/images/${env}`,
		{
			method: 'post',
			credentials: 'include',
			headers: headers,
			body: data1,
		}
	);

	const result = await promise.json();
	if (result.data.length) {
		return {
			status: 200,
			docs: result.data.map((item: IImage) => {
				return {
					url: item.url,
					width: item.width,
					height: item.height,
					imageId: item.imageId,
					extension: item.extension,
				};
			}),
		};
	}

	return {
		status: 200,
		docs: {
			url: result.data.url,
			width: result.data.width,
			height: result.data.height,
			imageId: result.data.imageId,
			extension: result.data.extension,
			originalName: result.data.originalName,
		},
		result: result.result,
	};
}
export async function DeleteImage(body: { id: string; url: string }) {
	const headers = await commonRequestHeaders();

	const promise = await fetch(
		`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/images/deleteImageByElementAndElementId`,
		{
			method: 'delete',
			credentials: 'include',
			headers: headers,
			body: JSON.stringify(body),
		}
	);

	const result = await promise.json();

	return {
		status: 200,
		result: result.result,
	};
}
export const getImageByElement = async ({
	element,
	elementId,
}: {
	element: string;
	elementId: string;
}): Promise<IResultImages> => {
	try {
		const headers = await commonRequestHeaders();
		const result = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/images/public/getImageByElement`,
			{
				method: 'post',
				credentials: 'include',
				body: JSON.stringify({ element, elementId }),
				headers: headers,
			}
		);
		const { data } = await result.json();

		if (data) {
			return {
				status: 200,
				data: data,
			};
		} else {
			return { status: 200, data: [] };
		}
	} catch (error) {
		return { status: 500, data: [] };
	}
};
export const setHighlight = async ({
	element,
	elementId,
	imageId,
}: {
	element: string;
	elementId: string;
	imageId: string;
}): Promise<boolean> => {
	try {
		const headers = await commonRequestHeaders();
		const result = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/images/setHighlight`,
			{
				method: 'post',
				credentials: 'include',
				body: JSON.stringify({ element, elementId, imageId }),
				headers: headers,
			}
		);
		const { data } = await result.json();

		if (data) {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		return false;
	}
};
export const imageRotatebyDegrees = async ({
	url,
	action,
	elementId,
}: {
	url: string;
	action: string;
	elementId: string;
}): Promise<boolean> => {
	try {
		const headers = await commonRequestHeaders();
		const result = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/images/imageRotatebyDegrees`,
			{
				method: 'post',
				credentials: 'include',
				body: JSON.stringify({ url, action, elementId }),
				headers: headers,
			}
		);
		const { data } = await result.json();

		if (data) {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		return false;
	}
};
