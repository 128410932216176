import { IResultAdvertisingFilter } from '@negotium/models';
import commonRequestHeaders from '../common/requestHeaders';
import { AxiosError } from 'axios';

export const getAdvertisementsActives =
	async (): Promise<IResultAdvertisingFilter> => {
		try {
			const headers = await commonRequestHeaders();
			const promise = await fetch(
				`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/advertisings/public/active`,
				{
					method: 'get',
					credentials: 'include',
					headers: headers,
				}
			);

			if (!promise.ok) {
				if (promise.status === 401) {
					return { status: 401, docs: [], total: 0 };
				}
			}
			const { data, result, message } = await promise.json();
			if (result === 2) {
				throw new Error(message);
			}

			return {
				status: 200,
				docs: data,
				total: data.length,
			};
		} catch (error) {
			throw new Error((error as AxiosError).message);
		}
	};
